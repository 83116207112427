import React from 'react';
import Pentester from "../img/pentester.jpg"
import Diagram from "../img/Diagram.png"
import Whitebox from "../img/whitebox.png"

class Pentest extends React.Component {
    render() {
      return (
        <div className="article">
          <h1>Qu'est-ce qu'un Pentest ?</h1>
          <hr/>
          <p className="tabulation">Un pentest (contraction de <strong>Pen</strong>etration <strong>Test</strong> = test d'intrusion) consiste à se mettre dans la peau d’un attaquant qui tente de s’introduire dans le SI d'une entreprise pour y réaliser des actions malveillantes. C'est un test d’intrusion qui vise à pénétrer un réseau de manière offensive, et plus ou moins réaliste, pour remonter les vulnérabilités au client afin qu'il les corrige. <br/><br/>La mission d'un pentester est donc de :</p>

          <img id="pentester_img" src={Pentester} alt="Pentester"/>

          <ul>
              <li>Etablir un scénario de compromission en fonction des vulnérabilités trouvées</li>
              <li>Lister l'ensemble des informations sensibles récupérables</li>
              <li>Détailler chaque vulnérabilité détectée au cours de ses tests</li>
              <li>Tester l’efficacité des systèmes de détection d’intrusion (IDS/IPS)</li>
              <li>Présenter un rapport et une restitution de ses découvertes au client</li>
              <li>Proposer des recommandations pour corriger les vulnérabilités</li>
          </ul>

          <br/>
          <hr/>
          <br/>

          <h2>Les différents modes de pentest :</h2>
          <br/>

          <ul>
              <li><strong>Black Box</strong> : position d'un attaquant externe n'ayant aucune information sur sa cible ou seulement des informations trouvées publiquement.</li>
              <li><strong>White Box</strong> : toutes les infos (plan réseau, adresses IP, comptes utilisateurs, ...) sont disponibles.</li>
              <li><strong>Grey Box</strong> : certaines infos, comme un compte utilisateur valide et l’accès à des applications métiers (scénario d'un employé malveillant). </li>
          </ul>

          <img id="whitebox_img" src={Whitebox} alt="Whitebox"/>

          <br/>

          <p className="tabulation">Il existe également des missions <strong>RedTeam</strong> visant à simuler ce que l’on pourrait décrire comme une APT (Advanced Persistent Threat) où "Tous les moyens sont bons" (exception faite de la <a href="https://medium.com/@fhbro/rubber-hose-attack-d6d6cea76d64">Rubber Hose Attack</a> ^^') on peut donc facilement imaginer un scénario de phishing des employés ou même d'intrusion physique dans les locaux pour la compromission initiale. 
          </p> 
          <p><u>Voir le projet</u> : Fabrication d'un implant/backdoor dans la section <strong>Redteam</strong></p>

          <br/>
          <hr/>
          <br/>

          <h2>Méthodologie classique d'un pentest web</h2>  

          <img id="diagram_img" src={Diagram} alt="méthodo pentest"/>

          <br/>
          <hr/>
          <br/>
        </div>
      );
    }
  }

  export default Pentest;