import React from 'react';


class Smb extends React.Component {
    render() {
      return (
        <div className="article">
          <h1>Le protocole SMB</h1>
          <hr/>
          <h2>Lorem ipsum</h2>
          <p>Neque egestas congue quisque egestas diam in. In hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Nisl nunc mi ipsum faucibus vitae aliquet. Ut lectus arcu bibendum at varius vel pharetra vel turpis. Et netus et malesuada fames ac turpis egestas integer eget. Morbi tristique senectus et netus et malesuada fames. At in tellus integer feugiat scelerisque. Aliquam nulla facilisi cras fermentum odio eu feugiat pretium nibh. Erat imperdiet sed euismod nisi porta lorem. A cras semper auctor neque vitae tempus quam pellentesque nec. Tempus egestas sed sed risus pretium quam. At tellus at urna condimentum. Leo vel orci porta non pulvinar neque laoreet. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum curabitur. Nulla facilisi morbi tempus iaculis urna.</p>
        </div>
      );
    }
  }

  export default Smb;